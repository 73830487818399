import React from 'react';
import "./Info.scss";

function Info() {
    return (
        <div className="info-wrapper animate__animated animate__zoomInDown">
            <div className="greetings-wrapper">
                <div className="greetings-logo"></div>
                <div className="greetings-text">Hey!<br/> Welcome to the new Jnhhgamingscripts site.<br/> </div>
            </div>
        </div>
    );
}

export default Info;
